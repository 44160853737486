<template>
    <div>
        <table class="small striped mb-4" id="alerts_table">
            <thead>
                <tr>
                    <th> Name </th>
                    <th> Email Address </th>
                    <th> </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="person in persons" :key="person.PersonID">
                    <td class="col-md-2">{{ person.Name }} </td>
                    <td class="col-md-4">{{ person.Email }}</td>
                    <td class="col-md-2 text-center">
                        <a href="#" @click="loginAsUser(person.UserID)" class="btn btn-sm btn-success">
                            Login
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType } from 'vue';
import { SearchPerson } from '@/interfaces/admin';
import axios from 'axios';
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';

export default defineComponent({
    props: {
        persons: {
            type: Object as PropType<SearchPerson[]>,
            required: true
        },
    },

    setup(props) {
        const authStore = useAuthStore();
        const router = useRouter();

        const loginAsUser = async (userID: number) => {
            try {
                // Use the store's action to start impersonation
                await authStore.startImpersonation(userID);
                router.push({ name: 'participant.dashboard' });
                // Optionally, handle any post-login logic, like redirecting the user
            } catch (error) {
                console.error("Impersonation error:", error);
            }
        };

        // Expose to template
        return {
            loginAsUser,
        };
    }
});
</script>