<template>
  <div class="container">
    <div class="d-flex align-items-center gap-4">
      <h5 class="text-center mt-4 mb-4"> Show: </h5>
      <router-link :to="{ name: 'superadmin.requests', params: { status: 1 } }"
        class="text-decoration-none text-secondary" :class="{ 'active-filter': status === '1' }">
        Open
      </router-link>
      <router-link :to="{ name: 'superadmin.requests', params: { status: 2 } }"
        class="text-decoration-none text-secondary" :class="{ 'active-filter': status === '2' }">
        Approved
      </router-link>
      <router-link :to="{ name: 'superadmin.requests', params: { status: 3 } }"
        class="text-decoration-none text-secondary" :class="{ 'active-filter': status === '3' }">
        Rejected
      </router-link>
    </div>

    <div v-if="success" class="alert alert-success">
      {{ success }}
    </div>

    <div v-if="error" class="alert alert-danger">
      {{ error }}
    </div>

    <div v-if="loading">
      Loading...
    </div>

    <div v-else-if="requests.length > 0">
      <button class="btn btn-success mb-4" @click="exportToExcel">
        {{ loadingExport ? 'Exporting...' : 'Export To Excel' }}
      </button>

      <table class="striped small">
        <thead>
          <tr>
            <th> Participant ID </th>
            <th> Name </th>
            <th> Web Submission Date </th>
            <th> Request Type </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="request in requests" :key="request.UIAdminID">
            <td>
              <router-link :to="{ name: 'superadmin.request.review', params: { UIAdminID: request.UIAdminID } }">
                {{ request.PersonID }}
              </router-link>
            </td>
            <td>
              <router-link :to="{ name: 'superadmin.request.review', params: { UIAdminID: request.UIAdminID } }">
                {{ request.Name }}
              </router-link>
            </td>
            <td>{{ request.WebSubmissionDate }} </td>
            <td>{{ request.RequestType }} </td>
          </tr>
        </tbody>
      </table>
      <PaginationComponent :paginator="paginator" @fetch-data="fetchPaginationData" />
    </div>

    <div v-else class="d-flex align-items-center justify-content-center gap-4">
      <p class="text-center"> No requests </p>
    </div>
  </div>
</template>

<script lang="ts">
import { watch, ref, onMounted, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { Request, Paginator } from '@interfaces/interfaces';
import { saveAs } from 'file-saver';
import axios from 'axios';

export default {
  setup() {
    const success = ref(null);
    const loading = ref(false);
    const error = ref(null);
    const requests: Ref<Request[]> = ref([]);
    const paginator: Ref<Paginator[]> = ref([]);
    const route = useRoute();
    const status = ref(route.params.status || '1');
    const exportExcel = ref(0);
    const loadingExport = ref(false);
    const page: Ref<number> = ref(1);

    watch(
      () => route.params.status, (newStatus) => {
        status.value = newStatus || '1';
        fetchData(false)
      }
    );

    const exportToExcel = async () => {
      loadingExport.value = true;
      exportExcel.value = 1;
      await handleDownload();
      exportExcel.value = 0;
      loadingExport.value = false;
    }

    // Handle Download Excel File
    const handleDownload = async () => {
      try {
        const response = await axios.get(`api/administrator/change-requests/${status.value}`, {
          params: { Export: exportExcel.value },
          responseType: 'blob',
        });

        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });

        saveAs(blob, 'requests.xlsx');
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    };

    const fetchData = async (isLoading: boolean) => {
      if (isLoading)
        loading.value = true;

      await axios.get(`api/administrator/change-requests/${status.value}`, { params: { page: page.value, Export: exportExcel.value } })
        .then(response => {
          requests.value = response.data.Requests;
          paginator.value = response.data.Paginator;
        })
        .catch(error => {
          console.error('There was an error!', error);
        }).finally(() => {
          if (isLoading)
            loading.value = false;
        });
    };

    const fetchPaginationData = (pageNumber: number) => {
      page.value = pageNumber;
      fetchData(false);
    }

    onMounted(async () => await fetchData(true));

    return {
      status,
      success,
      loading,
      loadingExport,
      error,
      requests,
      paginator,
      exportToExcel,
      fetchData,
      fetchPaginationData
    };
  },
};

</script>

<style>
.active-filter {
  font-weight: bold;
}
</style>
