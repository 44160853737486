<template>
    <div class="plan-content">
        <BeneficiaryCard title="Beneficiary" mode="multiple" :data="beneficiaries" :deleteItem="true" :hasDivider="true"
            customClass="green-card beneficiary-card" />
        <form id="assign-fund-form" class="form assign-fund" method="post">
            <h2> Select Fund </h2>
            <FundCard title="Health & Welfare" />
            <FundCard title="Pension" />
            <FundCard title="Vacation" />
            <FundCard title="Annuity" />

            <div class="action-buttons">
                <a href="" class="btn btn-primary plan-modal"
                    @click.prevent="openModal('success-modal', null, 'modal-success', null, modalProps)"> Save
                    Beneficiary </a>
                <a href="" @click.prevent="closeModal" class="btn btn-secondary"> Discard </a>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useModalStore } from '@/stores/modal';
import BeneficiaryCard from '@/components/cards/BeneficiaryCard.vue';
import FundCard from '@/components/cards/FundCard.vue';

export default defineComponent({
    components: {
        BeneficiaryCard,
        FundCard
    },
    setup(_, { emit }) {
        const modalStore = useModalStore();

        const closeModal = () => {
            emit('close');
        }

        return {
            beneficiaries: [
                {
                    Name: 'Sophie A. Grant',
                    SSN: 'XXX-XX-5746',
                    DOB: '07/07/1987',
                    Relation: 'Spouse',
                },
                {
                    Name: 'Sophie A. Grant',
                    SSN: 'XXX-XX-5746',
                    DOB: '07/07/1987',
                    Relation: 'Spouse',
                },
            ],
            modalProps: {
                title: 'Fund Assigned',
                content: {
                    type: "text",
                    value: "New funds has been assigned to Sophie A. Grant & Eli James Grant",
                    icon: 'iatse-icon-user-verified'
                },
            },
            closeModal,
            openModal: modalStore.openModal
        };
    },
})
</script>