<template>
  <div class="card" :class="{ 'active': isVisible }">
    <div class="card-header">
      <h2>{{ title }}</h2>
      <a href="#" class="btn btn-secondary card-toggle-control" :class="{ 'hidden': isVisible }"
        @click.prevent="toggleCardContent">
        Assign Fund
      </a>
      <i class="iatse-icon-arrow-down card-toggle-control" :class="{ hidden: !isVisible }"
        @click="toggleCardContent"></i>
    </div>

    <transition name="slide-fade">
      <div class="card-content card-toggle" :class="{ 'active': isVisible }" v-if="isVisible">
        <RadioButton label="Select Level" :options="levels" :name="title" />
        <RangeSlider label="Assign Fund Percentage" :sliders="sliders" :delete-button="true" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Slider, RadioOption } from '@/interfaces/interfaces';
import RadioButton from '@/components/form/RadioButton.vue';
import RangeSlider from '@/components/form/RangeSlider.vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
  },
  components: {
    RadioButton,
    RangeSlider,
  },
  setup(props) {
    const isVisible = ref(false);
    const levels: RadioOption[] = [
      { label: 'Primary', value: 'Primary' },
      { label: 'Secondary', value: 'Secondary' },
      { label: 'Tertiary', value: 'Tertiary' },
    ];

    const sliders: Slider[] = [
      {
        label: 'Sophie A. Grant (Child)',
        min: 0,
        max: 100,
        step: 1,
        defaultValue: 50,
        id: 'first'
      },
      {
        label: 'Eli James Grant (Child)',
        min: 0,
        max: 100,
        step: 5,
        defaultValue: 50,
        id: 'second'
      },
      {
        label: 'Diane Factor Grant (Spouse)',
        min: 0,
        max: 100,
        step: 5,
        defaultValue: 50,
        id: 'third'
      }
    ];

    const toggleCardContent = () => {
      isVisible.value = !isVisible.value;
    };

    const assignFund = () => {
      console.log('Fund assigned');
    };

    return {
      levels,
      sliders,
      isVisible,
      toggleCardContent,
      assignFund,
    };
  }
});
</script>