<template>
    <div>
        <table class="small striped mb-4">
            <thead>
                <tr>
                    <th> Local </th>
                    <th> Email Address </th>
                    <th> Days to Expiration </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="local in locals" :key="local.LocalID">
                    <td class="col-md-2">{{ local.OrgName }} </td>
                    <td class="col-md-4">{{ local.Email }}</td>
                    <td class="col-md-4">{{ local.DaysToExpiration }}</td>
                    <td v-if="local.Inactive == 1" class="text-center">
                        <button @click="activate(local.UserID)" class="btn btn-sm btn-warning btn-block pin-width">
                            {{ local.Activating ? 'Activating...' : 'Activate' }}
                        </button>
                    </td>
                    <td v-else class="text-center">
                        <button @click="deactivate(local.UserID)" class="btn btn-sm btn-warning btn-block pin-width">
                            {{ local.Deactivating ? 'Deactivating...' : 'Deactivate' }}
                        </button>
                    </td>
                    <td v-if="local.Role == 'Admin'" class="text-center">
                        <button @click="changeRole(local.UserID)" class="btn btn-sm btn-info btn-block pin-width">
                            {{ local.ChangingRole ? 'Loading...' : 'Remove Admin' }}
                        </button>
                    </td>
                    <td v-else class="text-center">
                        <button @click="changeRole(local.UserID)" class="btn btn-sm btn-info btn-block pin-width">
                            {{ local.ChangingRole ? 'Loading...' : 'Make Admin' }}
                        </button>
                    </td>
                    <td class="text-center">
                        <button @click="resetPin(local.UserID)" class="btn btn-sm btn-info btn-block pin-width">
                            {{ local.ResettingPin ? 'Resetting Pin...' : 'Reset Pin' }}
                        </button>
                    </td>
                    <td class="text-center">
                        <button @click="loginAsUser(local.UserID)" class="btn btn-sm btn-success btn-block">
                            Login
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <PaginationComponent :paginator="paginator" @fetch-data="fetchPaginationData" />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { SearchLocal, Paginator } from '@/interfaces/admin';
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';
import PaginationComponent from '@components/utils/PaginationComponent.vue';

export default defineComponent({
    props: {
        locals: {
            type: Object as PropType<SearchLocal[]>,
            required: true
        },
        paginator: {
            type: Object as PropType<Paginator>,
            required: true
        },
    },
    components: {
        PaginationComponent
    },
    setup(_, { emit }) {
        const authStore = useAuthStore();
        const router = useRouter();

        const resetPin = (userID: number) => {
            emit('reset-pin', userID);
        }

        const changeRole = (userID: number) => {
            emit('change-role', userID);
        }

        const activate = (userID: number) => {
            emit('activate', userID);
        }

        const deactivate = (userID: number) => {
            emit('deactivate', userID);
        }

        const fetchPaginationData = (pageNumber: number) => {
            emit('fetch-paginator-data', pageNumber);
        }

        const loginAsUser = async (userID: number) => {
            try {
                await authStore.startImpersonation(userID);
                router.push({ name: 'local.dashboard' });
            } catch (error) {
                console.error("Impersonation error:", error);
            }
        };

        return {
            loginAsUser,
            resetPin,
            changeRole,
            activate,
            deactivate,
            fetchPaginationData
        };
    }
});
</script>

<style scoped>
.pin-width {
    width: 120px;
}
</style>