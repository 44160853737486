import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_RangeSlider = _resolveComponent("RangeSlider")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", { 'active': _ctx.isVisible }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("a", {
        href: "#",
        class: _normalizeClass(["btn btn-secondary card-toggle-control", { 'hidden': _ctx.isVisible }]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleCardContent && _ctx.toggleCardContent(...args)), ["prevent"]))
      }, " Assign Fund ", 2),
      _createElementVNode("i", {
        class: _normalizeClass(["iatse-icon-arrow-down card-toggle-control", { hidden: !_ctx.isVisible }]),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleCardContent && _ctx.toggleCardContent(...args)))
      }, null, 2)
    ]),
    _createVNode(_Transition, { name: "slide-fade" }, {
      default: _withCtx(() => [
        (_ctx.isVisible)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["card-content card-toggle", { 'active': _ctx.isVisible }])
            }, [
              _createVNode(_component_RadioButton, {
                label: "Select Level",
                options: _ctx.levels,
                name: _ctx.title
              }, null, 8, ["options", "name"]),
              _createVNode(_component_RangeSlider, {
                label: "Assign Fund Percentage",
                sliders: _ctx.sliders,
                "delete-button": true
              }, null, 8, ["sliders"])
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}