<template>
    <div class="section-content" :class="{ 'hidden': modals?.length }">
        <h1>My IATSE NBF Funds</h1>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <component v-if="healthLoaded" :is="healthComponent" :data="healthData" />
            <AnnuityPanel v-if="annuityData" :data="annuityData" />
            <PensionPanel v-if="pensionData" :data="pensionData" :alerts="alerts?.Pension || null" />
            <VacationPanel v-if="vacationData && vacationData?.ENABLED" />
        </div>
    </div>
</template>

<script lang="ts">
import { ref, onMounted, watch, defineAsyncComponent, Component, computed } from 'vue';
import { useVacationStore } from '@/stores/vacation';
import { VacationDashboardInfo } from '@/interfaces/interfaces';
import HealthNoCoverage from '../../components/participant/dashboard/funds/HealthNoCoverage.vue';
import axios from 'axios';
import AnnuityPanel from '../../components/participant/dashboard/AnnuityPanel.vue';
import PensionPanel from '../../components/participant/dashboard/PensionPanel.vue';
import VacationPanel from '../../components/participant/dashboard/VacationPanel.vue';
import { HealthDashboardInfo } from '@/interfaces/health';
import { useModalStore } from '@/stores/modal';

export default {
    setup() {
        const loading = ref(true);
        const annuityData = ref(null);
        const pensionData = ref(null);
        const vacationData = ref(null);
        const vacationStore = useVacationStore();
        const initialHealthData: HealthDashboardInfo = {
            Coverage: {
                Current: {
                    From: '',
                    To: '',
                    Plan: '',
                },
                Upcoming: undefined,
            },
            Plan: '',
            FundID: 0,
            Template: 'no_coverage',
            MrpTotal: 0,
            RmrpTotal: 0,
            IsAvailableForClaim: false,
        };
        const healthData = ref<HealthDashboardInfo>(initialHealthData);
        const healthLoaded = ref(false);
        const healthComponent = ref<Component>(HealthNoCoverage);
        const alerts = ref(null);
        const modals = computed(() => useModalStore().modals);

        const fetchData = () => {
            axios.get('api/participant/dashboard')
                .then(response => {
                    console.log('>>>> aici');
                    console.log(response.data.Pension);
                    annuityData.value = response.data.Annuity;
                    pensionData.value = response.data.Pension;
                    vacationData.value = response.data.Vacation;
                    healthData.value = response.data.Health;
                    healthLoaded.value = true;
                    alerts.value = response.data.Alerts;
                    vacationStore.setVacationData(response.data.Vacation);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })
                .finally(() => loading.value = false);
        };

        onMounted(fetchData);

        // Watcher to dynamically load the correct component
        watch(healthData, async (newData: HealthDashboardInfo) => {
            if (!newData) return;

            switch (newData.Template) {
                case 'plan_c':
                    healthComponent.value = defineAsyncComponent(() => import('../../components/participant/dashboard/funds/HealthPlanC.vue'));
                    break;
                case 'plan_c_cobra':
                    healthComponent.value = defineAsyncComponent(() => import('../../components/participant/dashboard/funds/HealthPlanCCobra.vue'));
                    break;
                case 'plan_a':
                    healthComponent.value = defineAsyncComponent(() => import('../../components/participant/dashboard/funds/HealthPlanA.vue'));
                    break;
                case 'plan_a_cobra':
                    healthComponent.value = defineAsyncComponent(() => import('../../components/participant/dashboard/funds/HealthPlanACobra.vue'));
                    break;
                case 'mrp':
                    healthComponent.value = defineAsyncComponent(() => import('../../components/participant/dashboard/funds/HealthPlanMRP.vue'));
                    break;
                case 'no_coverage':
                default:
                    healthComponent.value = defineAsyncComponent(() => import('../../components/participant/dashboard/funds/HealthNoCoverage.vue'));
                    break;
            }

        }, { immediate: true });

        return {
            healthData, healthLoaded, annuityData, pensionData, vacationData, healthComponent, modals, loading
        };
    },

    components: {
        AnnuityPanel,
        PensionPanel,
        VacationPanel
    }
};
</script>