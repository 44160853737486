<template>
    <div class="form account-settings">
        <div class="d-flex w-full gap-2" v-if="selectedOption.key">
            <SelectComponent :label="'Security Question' + (index > 1 ? ' ' + index : '')"
                v-model:selectedOption="selectedOption" :options=options :readonly="true" v-if="selectedOption.key" />

            <transition name="slide-fade">
                <div class="d-flex align-items-end w-full gap-2"  v-if="!isEditable && selectedOption.key">
                    <a href="" @click.prevent="toggleEditable" class="btn btn-primary action-btn"> Change </a>
                    <a href="" @click.prevent="removeQuestion" class="btn btn-secondary action-btn"> Remove </a>
                </div>
            </transition>
        </div>

        <transition name="slide-fade">
            <div class="d-flex w-full justify-content-center gap-2" v-if="isEditable && selectedOption.key">
                <SelectComponent :label="'Edit Security Question' + (index > 1 ? ' ' + index : '')"
                    :selectedOption="selectedOption" :options=options @change="handleQuestion" />

                <TextInput v-model:modelValue="localSelectedQuestions[index].Answer" type="text"
                    :label="'Edit Security Answer' + (index > 1 ? ' ' + index : '')" placeholder="Edit Security Answer"
                    :reset="resetAnswer" />
            </div>
        </transition>

        <div class="d-flex w-full justify-content-center gap-2" v-if="!selectedOption.key && showAddNewQuestion">
            <SelectComponent label="Add New Security Question" :options=options @change="handleQuestion" />

            <TextInput v-model:modelValue="localSelectedQuestions[index].Answer" type="text"
                :label="'Add New Security Answer' + (index > 1 ? ' ' + index : '')"
                placeholder="Enter New Security Answer" />
        </div>

        <div class="d-flex w-full gap-2" v-if="showAddNewQuestion || isEditable">
            <button type="submit" class="btn btn-primary action-btn"> Update </button>
            <a href="" @click.prevent="toggleEditable" class="btn btn-secondary action-btn"> Discard </a>
        </div>
        <div class="w-full" v-if="hasMultipleQuestions || (!selectedOption.key && !showAddNewQuestion)">
            <a href="" @click.prevent="addNewQuestion" class="btn btn-secondary add-btn">
                <p class="icon-wrapper"> <i class="iatse-icon-plus"> </i> </p> Add New Security Question
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, Ref, toRefs, watch, computed } from 'vue';
import { SecurityQuestion, SelectOption, SelectedQuestionType } from '@/interfaces/interfaces';
import { SelectedQuestion } from '@/interfaces/employer';
import SelectComponent from '@components/form/SelectComponent.vue';
import TextInput from '@components/form/TextInput.vue';

export default defineComponent({
    props: {
        label: String,
        questions: {
            type: Array as PropType<SecurityQuestion[]>,
            required: true
        },
        options: {
            type: Array as PropType<{ key: number, value: string }[]>,
            required: true
        },
        userQuestion: {
            type: Object as PropType<SecurityQuestion>,
            required: true,
        },
        selectedQuestions: {
            type: Array as PropType<SelectedQuestion[]| SelectedQuestion> | [],
            required: false,
        },
        questionId: Number,
        // The index represents the number of the security question and is applicable only to local and employer settings.
        index: {
            type: Number,
            required: true
        },
        resetInputs: Boolean,
        hasMultipleQuestions: {
            type: Boolean,
            default: false
        },
    },
    components: {
        SelectComponent,
        TextInput
    },
    setup(props, { emit }) {
        const isEditable = ref(false);
        const { selectedQuestions } = toRefs(props);
        const showAddNewQuestion = ref(false);
        const defaultQuestion = {
            [props.index]: { Question: 0, Answer: '', ExistingQuestion: props.questionId || 0 }
        }
        const questionToAdd = computed(() => {
            return props.selectedQuestions && Object.keys(props.selectedQuestions).length
                ? props.selectedQuestions
                : [defaultQuestion];
        });

        const localSelectedQuestions: Ref<SelectedQuestion[] | SelectedQuestion | SelectedQuestionType | number> = ref(questionToAdd.value);
        const resetAnswer = ref(false);

        const selectedOption = ref({
            key: props.userQuestion?.SecurityQuestionID,
            value: props.userQuestion?.Question,
        });

        // Reset Inputs and close editable fields
        watch(() => props.resetInputs,
            (newValue) => {
                if (newValue) {
                    localSelectedQuestions.value = {
                        [props.index]: { Question: 0, Answer: '', ExistingQuestion: props.questionId || 0 }
                    }

                    isEditable.value = false;
                    resetAnswer.value = true;
                }
            }
        );

        watch(() => props.userQuestion,
            (newQuestion) => {
                selectedOption.value = {
                    key: newQuestion.SecurityQuestionID,
                    value: newQuestion.Question,
                };
            },
            { deep: true }
        );


        watch(() => localSelectedQuestions.value,
            (newQuestion) => {
                console.log('@changed', localSelectedQuestions.value)
            },
            { deep: true }
        );

        // Update The Selected Question
        const handleQuestion = (option: SelectOption) => {
            const index = Number(option.key);
            // localSelectedQuestions.value[props.index].Question = index as number;
            (localSelectedQuestions.value as SelectedQuestion)[props.index].Question = index as number;

            emit('handle-question', localSelectedQuestions.value, props.index);
        };

        const toggleEditable = () => {
            isEditable.value = !isEditable.value;

            if(!selectedOption.value.key) {
                showAddNewQuestion.value = !showAddNewQuestion.value;
            }
        }

        const removeQuestion = () => {
            isEditable.value = !isEditable.value;
        }

        const addNewQuestion = () => {
            showAddNewQuestion.value = !showAddNewQuestion.value;
        }

        return {
            localSelectedQuestions,
            selectedOption,
            isEditable,
            resetAnswer,
            showAddNewQuestion,
            handleQuestion,
            toggleEditable,
            addNewQuestion,
            removeQuestion
        }
    }
})
</script>