<template>
    <div class="container mt-4">
        <Alerts :success="success" :errors="errors" />
        <div class="row">
            <div class="col-md-12 d-flex flex-column gap-4">
                <h1 class="text-center mb-4">
                    Admin View - Web Claim Submission
                </h1>
                <div v-if="loading"> Loading... </div>
                <!-- Filters -->
                <div v-else-if="!loading && claimsHistory?.length === 0 && !changePage"
                    class="d-flex flex-column gap-4">
                    <div class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2"> Claim Status: </span>
                        <div class="w-50">
                            <Multiselect v-model="filters.ClaimStatus" :options="statuses" placeholder="Type to search"
                                :searchable="true" :close-on-select="true" openDirection="bottom">
                            </Multiselect>
                        </div>
                    </div>
                    <div class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2"> Receipt Date: </span>
                        <div class="w-50 d-flex align-items-center gap-4">
                            <input type="date" v-model="filters.ReceiptDate.StartDate" class="form-control w-50"
                                required />
                            <span>-</span>
                            <input type="date" v-model="filters.ReceiptDate.EndDate" class="form-control w-50"
                                required />
                        </div>
                    </div>
                    <div class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2"> Participant ID: </span>
                        <input type="number" v-model="filters.PersonID" class="form-control w-50" required />
                    </div>
                    <div class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2"> New / Existing Claim: </span>
                        <div class="w-50">
                            <Multiselect v-model="filters.ClaimType" :options="types" placeholder="Type to search"
                                :searchable="true" :close-on-select="true" openDirection="bottom">
                            </Multiselect>
                        </div>
                    </div>
                    <div class="d-flex gap-2 justify-content-between w-100">
                        <router-link class="btn btn-secondary" :to="{ name: 'superadmin.dashboard' }">Back</router-link>
                        <button class="btn btn-primary w-25" @click="searchData">
                            {{ loadingSearch ? 'Searching...' : 'Search' }}</button>
                    </div>
                </div>

                <!-- Results -->
                <div v-else-if="!loadingSearch && (changePage || claimsHistory?.length > 0)">
                    <div class="d-flex justify-content-between">
                        <button class="btn btn-secondary col-md-3 mb-4" @click="resetFilters">
                            Change my current Filters
                        </button>
                        <button class="btn btn-warning col-md-3 mb-4" @click="downloadCSV">
                            {{ loadingExport ? 'Exporting...' : 'Export To CSV' }}
                        </button>
                    </div>
                    <table class="small striped table-bordered">
                        <thead>
                            <tr class="text-center align-middle">
                                <th>Claim #</th>
                                <th>Web Ref #</th>
                                <th>Rcpt Date</th>
                                <th>Participant ID</th>
                                <th>Participant Name </th>
                                <th>Patient Name </th>
                                <th>DoS </th>
                                <th>Relationship</th>
                                <th>Notes</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody v-if="changePage">
                            <tr class="text-center">
                                <td colspan="10">loading...</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <template v-for="entry in claimsHistory" :key="entry.ClaimID">
                                <tr class="text-center">
                                    <td>{{ entry.ClaimID }}</td>
                                    <td>{{ entry.NEICID }}</td>
                                    <td>{{ entry.DateReceived }}</td>
                                    <td>{{ entry.PersonID }}</td>
                                    <td>{{ entry.ParticipantName }}</td>
                                    <td>{{ entry.Name }}</td>
                                    <td>{{ entry.DatesOfService }}</td>
                                    <td>{{ entry.Relationship }}</td>
                                    <td>{{ entry.Notes }}</td>
                                    <td>{{ entry.ClaimStatus }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <PaginationComponent :paginator="paginator" @fetch-data="fetchPaginationData" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { onMounted, reactive, ref, Ref } from 'vue';
import { ClaimHistory } from '@interfaces/health';
import { Paginator } from '@/interfaces/admin';
import { saveAs } from 'file-saver';
import PaginationComponent from '@components/utils/PaginationComponent.vue';
import Alerts from "@/components/utils/AlertsComponent.vue";
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import 'vue-multiselect/dist/vue-multiselect.css';

interface Patient {
    ID: string;
    FullName: string;
}

interface Filters {
    ReceiptDate: {
        StartDate?: string,
        EndDate?: string,
    };
    PersonID: number | null;
    ClaimStatus: [];
    ClaimType: string | null;
    Page: number | null;
}

export default {
    components: {
        Alerts,
        Multiselect,
        PaginationComponent
    },

    setup() {
        const loading = ref(true);
        const loadingSearch = ref(false);
        const loadingExport = ref(false);
        const changePage = ref(false);
        const success = ref(null);
        const errors: Ref<string[] | string | null> = ref(null);
        const statuses = ref([]);
        const types = ['New', 'Existing'];
        const claimsHistory: Ref<ClaimHistory[]> = ref([]);
        const initialFilters = {
            ReceiptDate: {
                StartDate: null,
                EndDate: null,
            },
            PersonID: null,
            ClaimStatus: statuses.value[0],
            ClaimType: types[0],
            Page: 1,
        };
        const filters: Filters = reactive({ ...JSON.parse(JSON.stringify(initialFilters)) });
        const paginator: Paginator = reactive({ per_page: 15, total: 300, last_page: 300, current_page: 1 });

        const resetFilters = () => {
            claimsHistory.value = [];
            changePage.value = false;
            Object.assign(filters, JSON.parse(JSON.stringify(initialFilters)));
        }

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/participant/claims-search')
                .then(response => {
                    statuses.value = response.data.Statuses;
                    filters.ClaimStatus = statuses.value[0];
                    filters.ClaimType = types[0];
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        // Get Claims History
        const searchData = async () => {
            if (!changePage.value) {
                loadingSearch.value = true;
            }

            claimsHistory.value = [];

            await axios.post('api/administrator/claim-submissions', {
                ...filters
            })
                .then(response => {
                    claimsHistory.value = Object.values(response.data.Claims);
                    Object.assign(paginator, response.data.Paginator);
                })
                .catch(error => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }
                })
                .finally(() => {
                    loadingSearch.value = false;
                    changePage.value = false;

                    setTimeout(() => {
                        success.value = null
                        errors.value = []
                    }, 3500);
                })
        }

        const fetchPaginationData = async (pageNumber: number) => {
            filters.Page = pageNumber;
            changePage.value = true;
            await searchData();
        }

        const downloadCSV = async () => {
            loadingExport.value = true;

            await handleDownload();

            loadingExport.value = false;
        }

        // Handle Download CSV File
        const handleDownload = async () => {
            try {
                const response = await axios.post('api/administrator/claim-submissions', {
                    ...filters,
                    Export: true,
                }, {
                    responseType: 'blob',
                });

                const blob = new Blob([response.data], {
                    type: response.headers['content-type'],
                });

                saveAs(blob, 'claims.csv');
            } catch (error) {
                console.error('Error downloading file:', error);
            }
        };

        onMounted(async () => await fetchData());

        return {
            loading,
            loadingSearch,
            loadingExport,
            success,
            errors,
            filters,
            statuses,
            types,
            claimsHistory,
            paginator,
            changePage,
            fetchPaginationData,
            resetFilters,
            searchData,
            downloadCSV
        };
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
}
</style>
