<template>
    <div class="plan-content">
        <LoaderComponent v-if="loading"/>
        <div class="text-card">
            <p class="text-500-bold"> This screen contains a repository of messages you’ve previously viewed which are stored here for
                historic reference
            </p>
        </div>
        <div v-if="loading" class="col-md-12 text-center mb-4">Loading...</div>
        <div v-else-if="alerts.length > 0" class="alerts-wrapper">
            <div class="alerts-cards">
                <div class="bordered-card" v-for="alert in alerts" :key="alert.AlertID">
                    <div class="bordered-card-header">
                        <p class="text-grey"> {{ useDateFormatter(alert.DateCreated) }}</p>
                    </div>

                    <div class="bordered-card-body">
                        <p v-html="alert.Message"> </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <p class="col-md-12 text-center mb-4"> No archived alerts</p>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, onMounted, ref } from 'vue';
import { useDateFormatter } from "@/utils/dateFormatter";
import  { Alert } from "@/interfaces/interfaces";
import axios from 'axios';

export default {
    setup() {
        const loading = ref(false);
        const alerts: Ref<Alert[] | []> = ref([]);

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/participant/alerts/archived')
                .then(response => {
                    alerts.value = response.data;
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });

            loading.value = false;
        };

        onMounted(async () => {
            await fetchData();
        });

        return {
            alerts,
            loading,
            useDateFormatter
        };
    },
};
</script>