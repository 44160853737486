<template>
    <FundPanel cardId="card-hw">
         <template #icon>
             <i class="iatse-icon-fund-hw"></i>
         </template>
         <template #title>
             Health &amp; Welfare
         </template>
         <template #fund-cover>
            <div class="col-md-9">
                <div class="fund-cover fund-cover__hw">
                    <table>
                        <tr>
                            <td></td>
                            <td>Coverage Period: <strong>{{ data.Coverage.Current.From }}</strong> to <strong>{{ data.Coverage.Current.To }}</strong></td>
                        </tr>
                        <tr>
                            <td colspan="2">Coverage Type: <strong>{{ data.Coverage.Current.Plan }}</strong></td>
                        </tr>
                    </table>
                </div>
            </div>
         </template>
         <template #related-links>
             <a href="" class="btn btn-tertiary btn-large plan-modal" v-if="data.IsAvailableForClaim"
             @click.prevent="openModal('claims', 'Claims', 'plan-upload plan-card-info plan-c-mrp-claims')">View/Submit
             Claims</a>
             <a href="" class="btn btn-tertiary btn-large plan-modal"
                 @click.prevent="openModal('dependents', 'My Dependents', null)"> Dependents
             </a>
             <a href="" @click.prevent class="btn btn-tertiary btn-large plan-modal">ID Card(s)</a>
             <a href="https://octobercms.dev.digitalgarage.ro/en/resources" class="btn btn-tertiary btn-large"
                 target="_blank">Funds Resources</a>
         </template>
     </FundPanel>
 </template>

<script lang="ts">
    import { defineComponent, toRefs} from 'vue';
    import { HealthDashboardInfo } from '@/interfaces/health';
    import { useModalStore } from '@/stores/modal';
    import FundPanel from '@/components/cards/FundPanel.vue';

    export default defineComponent({
        props: {
            data: {
                type: Object as () => HealthDashboardInfo,
                required: true
            }
        },

        setup(props) {
            // Destructure props using toRefs to maintain reactivity
            const { data } = toRefs(props);

            const visibleContent = true;

            const openModal = useModalStore().openModal;

            return {
                visibleContent
            };
        },

        components: {
            FundPanel
        }
    });
</script>